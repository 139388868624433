import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ButtonBase from '@mui/material/ButtonBase'
import Button from '@mui/material/Button'
import BinanceContext from '../shared/BinanceContext'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ModalView from './Popups/ModalView'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const ConnectionImg = styled('img')({
  width: '5vw',
})

export default function BinanceConnection() {
  const [step, setStep] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const { binance_get_data, binance_patch_connection, isConnected } = useContext(BinanceContext)
  const [isFormInvalid, setIsFormInvalid] = useState(false)

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  const handlePreviousStep = () => {
    setStep((prevState) => prevState - 1)
  }

  const setStepOne = () => {
    setStep(1)
  }

  const validate = (values) => {
    if (values.api_key !== '') {
      setIsFormInvalid(false)
      return false
    } else {
      setIsFormInvalid(true)
      return true
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      api_key: apiKey,
    }
    if (!validate(payload)) {
      await binance_patch_connection(payload)
      handleNextStep()
    }
  }

  const handleLastStep = () => {
    setOpenModal(false)
    setStepOne()
  }

  const fetchData = async () => {
    await binance_get_data()
  }

  const handleOpenModal = () => [setOpenModal(true)]

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Paper
      sx={{
        p: 2,
        margin: '20px -60px',
        maxWidth: 600,
        height: 260,
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src="/images/binance_logo.png" style={{ background: 'white' }} />
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          container
          sx={{ border: '2px solid #F3F3F3', margin: '15px 0 0 15px', borderRadius: '20px' }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
              Binance connection
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography gutterBottom variant="h6" sx={{ flex: 2.5 }}>
                {isConnected
                  ? 'Successfully connected to Binance'
                  : 'Binance has not yet been connected'}
              </Typography>
              <Box sx={{ flex: 1 }}>
                {isConnected ? (
                  <ConnectionImg src="/images/ok.svg" alt="Connected" />
                ) : (
                  <ConnectionImg src="/images/not_ok.svg" alt="Not connected" />
                )}
              </Box>
            </Box>
            <Grid item>
              <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleOpenModal}>
                {isConnected ? 'Edit connection' : 'Configure connection'}
              </Button>
            </Grid>
            {step === 1 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={1}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/1_login.png"
                text={'1/14 Login to Binance website on your computer in a browser. '}
              />
            )}
            {step === 2 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={2}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/2_api_management.png"
                text={
                  '2/14 In Binance, click on the user icon and go to the API Management tab (Zarządzanie API)'
                }
              />
            )}
            {step === 3 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={3}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/3_create_api.png"
                text={'3/14 In Binance, click on "Create API"'}
              />
            )}
            {step === 4 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={4}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/4_self_generated.png"
                text={'4/14 In Binance, click on "Self-generated" and then the "Next" button'}
              />
            )}
            {step === 5 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={5}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/5_copy_and_paste_public_key.png"
                text={'5/14 Copy public key from RETI then paste it into Binance and click "Next"'}
              />
            )}
            {step === 6 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={6}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/6_enter_api_name.png"
                text={'6/14 Enter any name, e.g. "RETI"'}
              />
            )}
            {step === 7 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={7}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/7_pass_security_verification_puzzle.png"
                text={'7/14 Pass "Security verification" puzzle'}
              />
            )}
            {step === 8 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={8}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/8_pass_security_verification_codes.png"
                text={'8/14 Pass "Security verification" codes'}
              />
            )}
            {step === 9 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={9}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/9_click_on_edit_restrictions.png"
                text={'9/14 Click on "Edit restrictions"'}
              />
            )}
            {step === 10 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={10}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/10_check_enable_spot_trading_and_save.png"
                text={'10/14 Check the box "Enable Spot & Margin Trading" and click "Save"'}
              />
            )}
            {step === 11 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={11}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/11_confirm_permission_expired.png"
                text={'11/14 Click on "Confirm" button'}
              />
            )}
            {step === 12 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={12}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/12_pass_security_verification_code.png"
                text={'12/14 Pass "Security verification" codes'}
              />
            )}
            {step === 13 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={13}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                image="/images/binance_steps/13_copy_api_key.png"
                text={
                  '13/14 Click on the newly created "RSA - RETI" entry and copy the API Key value'
                }
              />
            )}
            {step === 14 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={14}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                setStepOne={setStepOne}
                handleSubmit={handleSubmit}
                apiKey={apiKey}
                setApiKey={setApiKey}
                isFormInvalid={isFormInvalid}
                // image="/images/binance_steps/step1.png"
                text={'14/14 Enter the copied API Key in the field below'}
              />
            )}
            {step === 15 && (
              <ModalView
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                step={15}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                setStepOne={setStepOne}
                handleLastStep={handleLastStep}
                // image="/images/binance_steps/step1.png"
                text={
                  isConnected
                    ? 'Congratulations! You are connected to Binance!'
                    : 'Something went wrong. Try again later'
                }
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
