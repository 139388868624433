import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { IconButton, Modal } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import BinanceContext from '../../shared/BinanceContext'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
}

const StepOneImg = styled('img')({
  width: '40vw',
})

const ModalView = ({
  handlePreviousStep,
  handleNextStep,
  handleSubmit,
  step,
  image,
  text,
  openModal,
  handleCloseModal,
  setStepOne,
  setApiKey,
  handleLastStep,
  isFormInvalid,
}) => {
  const { binance_get_data } = useContext(BinanceContext)
  const copyRSA = async () => {
    const binanceConnection = await binance_get_data()
    navigator.clipboard.writeText(binanceConnection.data.public_key)
  }
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="binance-connection"
        aria-describedby="binance-connection"
      >
        <Box sx={style}>
          <IconButton
            sx={{ cursor: 'pointer', float: 'right', mt: -3, mr: -2 }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Binance Connection
          </Typography>
          <Box>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {step === 1 ? (
                <>
                  {text}
                  <a href="https://accounts.binance.com/en/login" target="_blank" rel="noreferrer">
                    Click to redirect
                  </a>
                </>
              ) : (
                <>{text}</>
              )}
            </Typography>
            {step === 5 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Button sx={{ mt: 2 }} onClick={copyRSA}>
                  Click to copy PUBLIC KEY
                </Button>
              </Box>
            )}
            {step === 14 && (
              <>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <TextField
                      id="api-key"
                      label="Binance API Key"
                      multiline
                      maxRows={4}
                      helperText={isFormInvalid && 'Api Key field must be filled'}
                      onChange={(e) => setApiKey(e.target.value)}
                      required
                    />
                  </div>
                </Box>
              </>
            )}
            {step !== 14 && step !== 15 && <StepOneImg src={image} alt="Step 1" />}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              {step !== 14 && step !== 15 && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleNextStep}
                >
                  Next
                </Button>
              )}
              {step === 14 && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
              {step === 15 && (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleLastStep}
                  >
                    Finish
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={setStepOne}
                  >
                    Back to beginning
                  </Button>
                </>
              )}
              {step !== 1 && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handlePreviousStep}
                >
                  Previous
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ModalView
