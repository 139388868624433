import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ButtonBase from '@mui/material/ButtonBase'
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import MuiInput from '@mui/material/Input'
import PercentIcon from '@mui/icons-material/Percent'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import Button from '@mui/material/Button'
import BinanceContext from '../components/shared/BinanceContext'

const Input = styled(MuiInput)`
  width: 42px;
`

const Img = styled('img')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})

export default function SavingPlan() {
  const [planType, setPlanType] = useState('PR')
  const [statusCode, setStatusCode] = useState(0)
  const [summaryView, setSummaryView] = useState(false)
  const [value, setValue] = useState(10)
  const [autoPurchase, setAutoPurchase] = useState(false)
  const [ignoreTransactionsAbove, setIgnoreTransactionsAbove] = useState(100)
  const maxValue = planType === 'PR' ? 50 : 200
  const {
    binance_post_saving_settings: binance_create_saving_settings,
    binance_get_saving_settings,
    binance_update_saving_settings,
    binanceError,
  } = useContext(BinanceContext)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleTransactionValueChange = (event) => {
    setIgnoreTransactionsAbove(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0)
    } else if (value > 100) {
      setValue(100)
    }
  }

  const handleTransactionBlur = () => {
    if (ignoreTransactionsAbove < 100) {
      setIgnoreTransactionsAbove(100)
    } else if (ignoreTransactionsAbove > 5000) {
      setIgnoreTransactionsAbove(5000)
    }
  }

  const handleChange = (event) => {
    setPlanType(event.target.value)
    setValue(10)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let payload = {}
    if (planType === 'PR') {
      payload = {
        plan_type: planType,
        percentage_value: value,
        ignore_transaction_above: ignoreTransactionsAbove,
        saving_enabled: autoPurchase,
      }
    } else {
      payload = {
        plan_type: planType,
        amount_value: value,
        ignore_transaction_above: ignoreTransactionsAbove,
        saving_enabled: autoPurchase,
      }
    }
    if (statusCode === 200) {
      await binance_update_saving_settings(payload)
    } else await binance_create_saving_settings(payload)
    setSummaryView(true)
  }

  const getSettings = async () => {
    await binance_get_saving_settings().then((r) => {
      if (r) {
        setStatusCode(r.status)
        setPlanType(r.data.plan_type)
        if (r.data.plan_type === 'PR') {
          setValue(r.data.percentage_value)
        } else setValue(r.data.amount_value)
        if (r.data.saving_enabled) {
          setAutoPurchase(r.data.saving_enabled)
        }
        if (r.data.ignore_transaction_above) {
          setIgnoreTransactionsAbove(r.data.ignore_transaction_above)
        }
        if (r.status === 200) {
          setSummaryView(true)
        } else setSummaryView(false)
      }
    })
  }
  const swapToEditMode = () => {
    setSummaryView(false)
  }
  const handleAutoPurchase = () => {
    setAutoPurchase((prevState) => !prevState)
  }

  useEffect(() => {
    getSettings()
    if (binanceError !== '') setStatusCode(binanceError)
  }, [])

  return (
    <Paper
      sx={{
        p: 2,
        margin: '20px -60px',
        maxWidth: 600,
        maxHeight: 620,
        flexGrow: 1,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}
      >
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src="/images/savings_plan_image.png" />
          </ButtonBase>
        </Grid>
        <Grid
          item
          sm
          sx={{ border: '2px solid #F3F3F3', margin: '15px 0 0 15px', borderRadius: '20px' }}
        >
          <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
            Savings plan settings
          </Typography>
          {summaryView ? (
            <Grid item sx={{ mr: 2 }}>
              <Box sx={{ mb: 1 }}>
                {planType === 'PR' && <Typography>{value}% per each transaction amount</Typography>}
                {planType === 'AM' && <Typography>{value} PLN per each transaction</Typography>}
                <Typography>
                  Ignore transactions above
                  <strong> {ignoreTransactionsAbove} PLN</strong>
                </Typography>
                <Typography>
                  Autopurchase is{' '}
                  {autoPurchase ? <strong>enabled</strong> : <strong>disabled</strong>}
                </Typography>
              </Box>
              <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={swapToEditMode}>
                Edit
              </Button>
            </Grid>
          ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Box>
                <FormControl sx={{ width: '340px' }}>
                  <InputLabel id="planType">Savings plan</InputLabel>
                  <Select
                    labelId="planType"
                    id="planType"
                    value={planType}
                    label="Plan type"
                    onChange={handleChange}
                    autoWidth={true}
                  >
                    <MenuItem value="PR">Percentage per each transaction amount</MenuItem>
                    <MenuItem value="AM">Fixed PLN amount per each transaction</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: 340 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>{planType === 'PR' ? <PercentIcon /> : <LocalAtmIcon />}</Grid>
                  <Grid item xs mt={2}>
                    <Typography>{planType === 'AM' ? 'Fixed amount' : 'Percentage'}</Typography>
                    <Slider
                      value={typeof value === 'number' ? value : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      max={maxValue}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Input
                      value={value}
                      size="small"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: maxValue,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ width: 'inherit' }}>
                    <Typography>Ignore transactions above</Typography>
                    <Tooltip title="It should be an amount between 100PLN and 5000 PLN">
                      <Input
                        value={ignoreTransactionsAbove}
                        fullWidth
                        onChange={handleTransactionValueChange}
                        onBlur={handleTransactionBlur}
                        inputProps={{
                          step: 50,
                          min: 100,
                          max: 5000,
                          type: 'number',
                        }}
                        sx={{ width: '340px' }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid sx={{ width: 'inherit' }}>
                    <Typography sx={{ m: 2 }}>Autopurchase every single day</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      color={autoPurchase ? 'success' : 'error'}
                      sx={{ ml: 2, width: '340px' }}
                      onClick={handleAutoPurchase}
                    >
                      {autoPurchase ? 'Enabled' : 'Disabled'}
                    </Button>
                  </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {statusCode === 200 ? 'Update' : 'Create'}
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
