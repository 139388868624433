import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Alert, Tooltip } from '@mui/material'
import AuthContext from '../components/shared/AuthContext'
import TermsAndConditions from '../components/TermsAndConditions'
import { emailRegex } from '../consts/regex'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://reti.tech/">
        RETI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

export default function Signup() {
  const { register } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [checked, setChecked] = useState(false)

  // Errors
  const [registerError, setRegisterError] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [password2Error, setPassword2Error] = useState(false)
  const [regulationsError, setRegulationsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    isPasswordsEqual()
  }, [password, confirmPassword])

  useEffect(() => {
    isCorrectPassword()
  }, [password])

  useEffect(() => {
    isEmail()
  }, [username])

  function isEmail() {
    if (username !== '') {
      if (!emailRegex.test(username)) {
        setUsernameError(true)
        return false
      }
    }
    setUsernameError(false)
    return true
  }

  function isPasswordsEqual() {
    if (confirmPassword !== '') {
      if (confirmPassword !== password) {
        setPassword2Error(true)
        return false
      }
    }
    setPassword2Error(false)
    return true
  }

  function isCorrectPassword() {
    let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,20})/
    if (password !== '') {
      if (!password.match(check)) {
        setPasswordError(true)
        return false
      }
    }
    setPasswordError(false)
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (
      usernameError === false &&
      passwordError === false &&
      password2Error === false &&
      checked === true
    ) {
      let payload = {
        username: username,
        password: password,
        regulations_accepted: checked,
      }
      await register(payload).catch((e) => {
        if (e.response.data.username) {
          setErrorMsg(e.response.data.username)
          setRegisterError(true)
        } else if (e.response.data.regulations_accepted) {
          setRegulationsError(e.response.data.regulations_accepted)
        }
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="/images/logo.png" alt="RETI logo" style={{ margin: '30px 0', width: '30vw' }} />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Username should be an email">
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Tooltip>
                {usernameError && <Alert severity="error">Username should be an email</Alert>}
                {registerError && <Alert severity="error">{errorMsg}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <Tooltip
                  title="Password should contain at least 1 upper letter, 1 number, 1 special character
                                and length of 8 characters."
                >
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Tooltip>
                {passwordError && (
                  <Alert severity="error">
                    Password should contain 8 characters, 1 number and 1 uppercase letter
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Confirm password"
                  label="Confirm password"
                  type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {password2Error && <Alert severity="error">Password are not the same</Alert>}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <TermsAndConditions setChecked={setChecked} checked={checked} />
                {regulationsError && <Alert severity="error">{regulationsError}</Alert>}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onSubmit={handleSubmit}
              disabled={!username || !password || !confirmPassword || !checked}
            >
              Sign Up
            </Button>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
