import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ButtonBase from '@mui/material/ButtonBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import BankListModal from './BankListModal'
import NordigenContext from '../shared/NordigenContext'
import RemoveConnection from './RemoveConnection'

const ConnectionImg = styled('img')({
  width: '5vw',
})
const Img = styled('img')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})
export default function NordigenIntegration() {
  const { getNordigenBanks, activateNordigen, getNordigenConnectedBanks } =
    useContext(NordigenContext)
  const [openModal, setOpenModal] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [bankList, setBankList] = useState()
  const [isActive, setIsActive] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
  }
  const getBankList = async () => {
    return await getNordigenBanks()
  }
  const handleOpenModal = async () => {
    await getNordigenBanks().then((banks) => {
      setBankList(banks.data)
      setOpenModal(true)
    })
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenRemoveModal = () => {
    setOpenRemoveModal(true)
  }
  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false)
  }

  const updateStateBankConnections = async () => {
    const response = await getNordigenConnectedBanks()
    if (response && response.data) {
      setIsActive(response.data.is_active)
    }
  }
  useEffect(() => {
    getBankList()
    updateStateBankConnections()
  }, [])

  return (
    <Paper
      sx={{
        p: 2,
        margin: '20px -60px',
        maxWidth: 600,
        height: 260,
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src="/images/nordigen_logo.svg" style={{ background: 'white' }} />
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          container
          sx={{ border: '2px solid #F3F3F3', margin: '15px 0 0 15px', borderRadius: '20px' }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
              Nordigen connection
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography gutterBottom variant="h6" sx={{ flex: 2.5 }}>
                {isActive
                  ? 'Successfully connected to your Bank account'
                  : 'Your bank account has not yet been connected'}
              </Typography>
              <Box sx={{ flex: 1 }}>
                {isActive ? (
                  <ConnectionImg src="/images/ok.svg" alt="Connected" />
                ) : (
                  <ConnectionImg src="/images/not_ok.svg" alt="Not connected" />
                )}
              </Box>
            </Box>
            <Grid item sx={{ mr: 4 }}>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color={isActive ? 'error' : 'primary'}
                onClick={isActive ? handleOpenRemoveModal : handleOpenModal}
              >
                {isActive ? 'Delete connection' : 'Configure connection'}
              </Button>
            </Grid>
            {openModal && (
              <BankListModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleCloseModal={handleCloseModal}
                bankList={bankList}
                activateNordigen={activateNordigen}
              />
            )}
            {openRemoveModal && (
              <RemoveConnection
                openRemoveModal={openRemoveModal}
                handleCloseRemoveModal={handleCloseRemoveModal}
                setIsActive={setIsActive}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
