import React, { useContext } from 'react'
import { Modal } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import NordigenContext from '../shared/NordigenContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
}

const RemoveConnection = ({ openRemoveModal, handleCloseRemoveModal, setIsActive }) => {
  const { removeNordigenConnection } = useContext(NordigenContext)
  const removeNordigen = async () => {
    await removeNordigenConnection().then(() => {
      setIsActive(false)
      handleCloseRemoveModal()
    })
  }
  return (
    <>
      <Modal
        open={openRemoveModal}
        onClose={handleCloseRemoveModal}
        aria-labelledby="bank-list"
        aria-describedby="bank-list"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ textAlign: 'center', mb: 2 }}>
            Connection removal
          </Typography>
          <Typography
            id="select-bank"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            Are you sure you want to remove the connection with the Bank? Then saving in Bitcoin
            will be not possible because there will be no transactions to analyze and to calculate
            the amount to save.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Button sx={{ mr: 4 }} variant="contained" onClick={handleCloseRemoveModal}>
              Back
            </Button>
            <Button variant="contained" color="error" onClick={removeNordigen}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default RemoveConnection
