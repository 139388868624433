import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useLocation, useNavigate } from 'react-router-dom'
import NordigenContext from '../shared/NordigenContext'
import Typography from '@mui/material/Typography'
import { Modal } from '@mui/material'
import Button from '@mui/material/Button'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
}

const ConfirmedNordigenIntegration = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  const referenceNo = query.get('ref')
  const connectingError = query.get('error')
  const { confirmNordigenActivation } = useContext(NordigenContext)
  const [connectionStatus, setConnectionStatus] = useState('')

  const getConnectionStatus = async () => {
    const response = await confirmNordigenActivation(referenceNo, connectingError)
    if (response && response.data) {
      setConnectionStatus(response.data)
    }
  }
  useEffect(() => {
    getConnectionStatus()
  }, [])
  return (
    <>
      <Modal
        open={true}
        aria-labelledby="bank-list"
        aria-describedby="bank-list"
        sx={{
          height: '50vh',
        }}
      >
        <Box sx={style}>
          <Typography
            id="select-bank"
            variant="h6"
            component="h2"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {connectionStatus !== '' ? connectionStatus : 'Something went wrong'}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <Button variant="contained" onClick={() => navigate('/')}>
              Back to homepage
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
export default ConfirmedNordigenIntegration
