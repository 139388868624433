import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
}

const BankTile = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '8px',
  padding: '8px',
  width: '80px',
  border: '1px silver solid',
  borderRadius: '12px',
})

const LogoBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const RedirectContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const TileContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
})

const BankListModal = ({ openModal, handleCloseModal, bankList, activateNordigen }) => {
  const [activeBankId, setActiveBankId] = useState(-1)
  const [selectedBank, setSelectedBank] = useState()
  const [informationModalOpened, setInformationModalOpened] = useState(false)

  const handleSelectBank = (bankId, index) => {
    setActiveBankId(index)
    setSelectedBank(bankId)
    setInformationModalOpened((prevState) => !prevState)
  }
  const showMsgToUser = async () => {
    setInformationModalOpened((prevState) => !prevState)
  }
  const handleRedirectToBank = async () => {
    const response = await activateNordigen(selectedBank)
    window.open(response.data.link, '_self')
  }
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="bank-list"
        aria-describedby="bank-list"
      >
        <Box sx={style}>
          {informationModalOpened ? (
            <RedirectContainer>
              <Typography id="select-bank" variant="h6" component="h2" sx={{ mb: 2 }}>
                Caution!
              </Typography>
              <Typography>
                You will be redirected to the Nordigen website and there you should log in to your
                bank account.
                <br />
                <strong style={{ color: 'red' }}>Important!</strong> Remember to select only one
                bank account. Ideally, it should be the one you use on a daily basis.
              </Typography>
              <Button variant="contained" onClick={handleRedirectToBank} sx={{ mt: 2 }}>
                Redirect
              </Button>
            </RedirectContainer>
          ) : (
            <>
              <Box>
                <IconButton
                  sx={{ cursor: 'pointer', float: 'right', mt: -3, mr: -2 }}
                  onClick={handleCloseModal}
                >
                  <CloseIcon />
                </IconButton>
                <Typography id="select-bank" variant="h6" component="h2">
                  Select the bank you want to connect to
                </Typography>
                <TileContainer>
                  {bankList.map((bank, index) => (
                    <BankTile
                      key={index}
                      onClick={() => handleSelectBank(bank.id, index)}
                      sx={{ borderColor: activeBankId === index ? 'black' : 'silver' }}
                    >
                      <Typography sx={{ fontSize: '8px' }}>{bank.name}</Typography>
                      <LogoBox>
                        <img src={bank.logo} alt="bank" style={{ width: '100%' }} />
                      </LogoBox>
                    </BankTile>
                  ))}
                </TileContainer>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button variant="contained" onClick={showMsgToUser}>
                  Next
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default BankListModal
