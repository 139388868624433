import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import AuthContext from './AuthContext'

const ProtectedRoute = ({ children, accessBy }) => {
  const { user } = useContext(AuthContext)

  if (accessBy === 'guest') {
    if (!user) {
      return children
    }
  } else if (accessBy === 'authenticated') {
    if (!user) {
      return <Navigate to="/login"></Navigate>
    }
    if (user) {
      return children
    }
  }

  return <Navigate to="/"></Navigate>
}

export default ProtectedRoute
