import React, { createContext, useState } from 'react'
import jwtInterceptor from './jwtInterceptor'

const NordigenContext = createContext()

export const NordigenContextProvider = ({ children }) => {
  const [nordigenError, setNordigenError] = useState('')
  const [bankConnected, setBankConnected] = useState(false)

  const getNordigenBanks = async () => {
    setNordigenError('')
    return await jwtInterceptor
      .get('/api/savings-to-crypto-app/nordigen-bank-list/')
      .catch((error) => setNordigenError(error.response?.data))
  }
  const getNordigenConnectedBanks = async () => {
    setNordigenError('')
    return await jwtInterceptor
      .get('/api/savings-to-crypto-app/nordigen-connection-view/')
      .catch((error) => setNordigenError(error.response?.data))
  }
  const getSummaryOfPurchases = async () => {
    setNordigenError('')
    // TODO: Sprawdzic jak obsłużyć server-side pagination i zaimplementować
    return await jwtInterceptor
      .get(`/api/savings-to-crypto-app/purchase-of-cryptocurrency-list/?limit=9999`)
      .catch((error) => setNordigenError(error.response?.data))
  }
  const activateNordigen = async (bankId) => {
    setNordigenError('')
    const response = await jwtInterceptor
      .get(`/api/savings-to-crypto-app/nordigen-activation-view/${bankId}/`)
      .catch((error) => setNordigenError(error.response.data))
    if (response.data !== '') {
      setBankConnected(true)
    }
    return response
  }
  const removeNordigenConnection = async () => {
    return await jwtInterceptor
      .delete('/api/savings-to-crypto-app/nordigen-connection-view/')
      .catch((error) => setNordigenError(error.response.data))
  }
  const confirmNordigenActivation = async (referenceNo, connectingError) => {
    let url = `/api/savings-to-crypto-app/confirm-nordigen-activation-view/?ref=${referenceNo}`
    if (connectingError) {
      url = `${url}&error=${connectingError}`
    }
    return await jwtInterceptor.get(url).catch((error) => setNordigenError(error.response.data))
  }

  const rebuyFromSummaryButton = async (id) => {
    return await jwtInterceptor
      .post(`/api/savings-to-crypto-app/purchase-of-cryptocurrency/${id}/buy-again/`)
      .catch((error) => setNordigenError(error.response.data))
  }

  return (
    <NordigenContext.Provider
      value={{
        getNordigenBanks,
        activateNordigen,
        confirmNordigenActivation,
        removeNordigenConnection,
        getNordigenConnectedBanks,
        getSummaryOfPurchases,
        rebuyFromSummaryButton,
        bankConnected,
        nordigenError,
      }}
    >
      {children}
    </NordigenContext.Provider>
  )
}

export default NordigenContext
