import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import { FormControlLabel } from '@mui/material'

export default function TermsAndConditions({ checked, setChecked }) {
  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  return (
    <FormControlLabel
      control={<Checkbox color="success" checked={checked} onChange={handleChange} />}
      label={
        <Link
          href="https://docs.google.com/document/d/1Kgu5SgBweXoqCBdtxKYlq8dPa4ZyW2aZ8apwN6pgFSk/edit?usp=sharing"
          target="_blank"
        >
          I accept the terms and conditions
        </Link>
      }
    />
  )
}
