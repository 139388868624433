import React, { createContext, useState } from 'react'
import jwtInterceptor from './jwtInterceptor'

const BinanceContext = createContext()

export const BinanceContextProvider = ({ children }) => {
  const [binanceError, setBinanceError] = useState('')
  const [statusCode] = useState(null)
  const [isConnected, setIsConnected] = useState(false)

  const binance_get_data = async () => {
    setBinanceError('')
    const response = await jwtInterceptor
      .get('/api/reti-app/binance-connection/')
      .catch((error) => setBinanceError(error.response?.data))
    if (response && response.data) {
      setIsConnected(response.data.is_connected)
    }
    return response
  }

  const binance_patch_connection = async (payload) => {
    setBinanceError('')
    const response = await jwtInterceptor
      .patch('/api/reti-app/binance-connection/', payload)
      .catch((error) => setBinanceError(error.response.data))
    if (response && response.data) {
      setIsConnected(response.data.is_connected)
    }
    return response
  }

  const binance_post_saving_settings = async (payload) => {
    setBinanceError('')
    await jwtInterceptor
      .post('/api/savings-to-crypto-app/saving-plan-settings/', payload)
      .catch((error) => setBinanceError(error.response.data))
  }

  const binance_update_saving_settings = async (payload) => {
    setBinanceError('')
    await jwtInterceptor
      .patch('/api/savings-to-crypto-app/saving-plan-settings/', payload)
      .catch((error) => setBinanceError(error.response.data))
  }

  const binance_get_saving_settings = async () => {
    setBinanceError(null)
    return await jwtInterceptor
      .get('/api/savings-to-crypto-app/saving-plan-settings/')
      .catch((error) => setBinanceError(error.response.status))
  }

  const binance_connection = async (payload) => {
    setBinanceError('')
    await jwtInterceptor
      .post('/api/reti-app/binance-connection/', payload)
      .catch((error) => setBinanceError(error.response.data))
  }

  return (
    <BinanceContext.Provider
      value={{
        binance_connection,
        binance_get_data,
        binance_patch_connection,
        isConnected,
        binance_post_saving_settings,
        binance_get_saving_settings,
        binance_update_saving_settings,
        binanceError,
        statusCode,
      }}
    >
      {children}
    </BinanceContext.Provider>
  )
}

export default BinanceContext
