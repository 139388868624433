import {
  Box,
  Typography,
  TextField,
  Container,
  ThemeProvider,
  createTheme,
  Button,
  CssBaseline,
  Alert,
} from '@mui/material'
import RetiLogo from '../components/RetiLogo/RetiLogo'
import { useState } from 'react'
import { emailRegex } from '../consts/regex'
import {
  EMAIL_ERROR_MESSAGE,
  REQUEST_ERROR_MESSAGE,
  EMAIL_SUCCESS_MESSAGE,
} from '../consts/emailResetMessages'
import axios from '../axios_settings'

const theme = createTheme()

export const ErrorMessage = ({ message }) =>
  message !== '' ? <Alert severity="error">{message}</Alert> : null

const PasswordRecovery = () => {
  const [email, setEmail] = useState('')
  const [requestError, setRequestError] = useState('')
  const [emailSuccess, setEmailSuccess] = useState('')

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/reset-password/', {
        email: email,
      })
      if (response.status === 200) {
        setEmailSuccess(EMAIL_SUCCESS_MESSAGE)
      }
    } catch (error) {
      setRequestError(REQUEST_ERROR_MESSAGE)
    }
  }

  const handleEmailChange = (e) => setEmail(e.target.value)

  const errorMessage = email !== '' && !emailRegex.test(email) ? EMAIL_ERROR_MESSAGE : ''

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RetiLogo />
          <Typography component="h1" variant="h5">
            Password recovery
          </Typography>
          <Box component="form" noValidate onSubmit={submitForm} mt={3} sx={{ width: '100%' }}>
            {emailSuccess === '' ? (
              <>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="E-mail"
                  type="email"
                  id="email"
                  autoComplete="email"
                  onChange={handleEmailChange}
                  value={email}
                  placeholder="Please provide your e-mail address"
                />
                <ErrorMessage message={errorMessage} />
                <ErrorMessage message={requestError} />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={errorMessage !== '' || email === ''}
                >
                  Send recovery email
                </Button>
              </>
            ) : (
              <Typography component="h2" variant="h6" textAlign="center" fontWeight="normal">
                {emailSuccess}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default PasswordRecovery
