import BinanceConnection from '../components/BinanceConnection/BinanceConnection'
import SavingPlan from '../components/SavingPlan'
import NordigenIntegration from '../components/NordigenIntegration/NordigenIntegration'
import Box from '@mui/material/Box'

export default function Home() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <Box sx={{ width: '600px' }}>
        <NordigenIntegration />
      </Box>
      <Box sx={{ width: '600px', ml: 2 }}>
        <BinanceConnection />
      </Box>
      <Box sx={{ width: '600px', mt: -4 }}>
        <SavingPlan />
      </Box>
    </Box>
  )
}
