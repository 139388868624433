import Navbar from 'react-bootstrap/Navbar'
import { Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import AuthContext from './AuthContext'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const UsernameBox = styled(Box)({
  display: 'block',
  padding: 'var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)',
  fontSize: 'var(--bs-nav-link-font-size)',
  fontWeight: 'var(--bs-nav-link-font-weight)',
  color: 'var(--bs-nav-link-color)',
  textDecoration: 'none',
})

const NavbarMenu = ({ children }) => {
  const { user, getUserData, logout } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const getUsername = async () => await getUserData()
  useEffect(() => {
    if (user) {
      getUsername().then((r) => {
        setUsername(r.data.username)
      })
    }
  }, [user])
  return (
    <>
      <Navbar variant="dark" style={{ backgroundColor: '#40909B' }}>
        <Navbar.Brand>
          <Nav.Link as={Link} to="/" style={{ marginLeft: '1em' }}>
            <img src="/images/logo2.png" alt="RETI logo" style={{ width: '2.5vw' }} />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {!user && (
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            )}
            {!user && (
              <Nav.Link as={Link} to="/register">
                Register
              </Nav.Link>
            )}
            {user && (
              <>
                <UsernameBox>Hello, {username}</UsernameBox>
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/summary-of-purchases/">
                  Summary
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/login"
                  onClick={() => {
                    logout()
                  }}
                >
                  Logout
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>{children}</Container>
    </>
  )
}

export default NavbarMenu
