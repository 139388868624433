import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import NordigenContext from '../shared/NordigenContext'
import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Moment from 'moment'

const SummaryOfPurchases = () => {
  const ColsDefinition = [
    {
      field: 'amount_pln',
      headerName: 'Amount PLN',
      width: 160,
    },
    {
      field: 'based_on_transactions',
      headerName: 'Based on transactions',
      width: 160,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      width: 160,
    },
    {
      field: 'purchase_for_a_day',
      headerName: 'Purchase for a day',
      width: 140,
    },
    {
      field: 'purchase_day',
      headerName: 'Purchase day',
      width: 140,
    },
    {
      field: 'purchase_type',
      headerName: 'Purchase type',
      width: 120,
    },
    {
      field: 'cryptocurrency',
      headerName: 'Cryptocurrency',
      width: 120,
    },
    {
      field: 'bought_crypto_quantity',
      headerName: 'Bought crypto quantity',
      width: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
    },
    {
      field: 'error_message',
      headerName: 'Details',
      width: 300,
    },
    {
      field: 'based_on_saving_plan',
      headerName: 'Saving plan',
      width: 140,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            sx={{ border: '1px silver solid' }}
            onClick={() => rebuy(params.row.id)}
          >
            Repeat
          </Button>
        </>
      ),
    },
  ]

  const { getSummaryOfPurchases, rebuyFromSummaryButton } = useContext(NordigenContext)
  const [dataFetched, setDataFetched] = useState(false)
  const [rebuyCounter, setRebuyCounter] = useState(0)
  const [data, setData] = useState()
  const rebuy = async (id) => {
    await rebuyFromSummaryButton(id).then(() => {
      setRebuyCounter((prevState) => prevState + 1)
    })
  }
  const getData = async () => {
    const response = await getSummaryOfPurchases().then((r) => {
      setDataFetched(true)
      return r.data.results.map((item) => ({
        id: item.id,
        amount_pln: item.amount_pln,
        based_on_transactions: item.based_on_transactions,
        created_at: Moment(item.created_at).format('LLL'),
        purchase_for_a_day: Moment(item.purchase_for_a_day).format('LL'),
        purchase_day:
          item.purchase_day && item.purchase_day != null
            ? Moment(item.purchase_day).format('LL')
            : '-',
        purchase_type: item.purchase_type == 'OD' ? 'On demand' : 'Cyclic',
        cryptocurrency: item.cryptocurrency,
        bought_crypto_quantity: item.bought_crypto_quantity,
        status: item.status,
        error_message: item.error_message,
        based_on_saving_plan: item.based_on_saving_plan,
        action: item.action,
      }))
    })
    setData(response)
  }

  useEffect(() => {
    getData()
  }, [rebuyCounter])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ m: '0 auto', mt: 2, mb: 2 }}>
        Saving summary
      </Typography>
      <Box sx={{ height: 400, width: '100%', borderRadius: '20px' }}>
        {dataFetched ? (
          <DataGrid
            style={{ borderRadius: '20px' }}
            sx={{ height: '80vh' }}
            rows={data}
            columns={ColsDefinition}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SummaryOfPurchases
